import Link from 'next/link';
import { stockbitIcon } from 'assets/base64icon';
import color from '@deprecated-ui/_style/color';
import Text from '@deprecated-ui/common/Text';
import Button from '@deprecated-ui/common/Button';
import Image from 'global/Image';
import { PROJECT_FOLDER, siteUrl } from 'constants/app';
import SEO from 'global/SEO';
import { Container, PageWrapper } from './styled';

const ErrorFeature = ({ statusCode }: { statusCode: number }) => (
  <Container>
    <SEO title="Error" />
    <PageWrapper>
      <Image src={stockbitIcon} alt="Stockbit" />
      <div>
        <Text as="h1" family="bold" color={color.nightRider} size="64px">
          Lost in Space
        </Text>
        <Text color={color.nightRider} size="16px">
          We can&apos;t find what you&apos;re looking for
        </Text>
        <Link href="/">
          <a>
            <Button>
              Go back home safely to {siteUrl}/{PROJECT_FOLDER}
            </Button>
          </a>
        </Link>
      </div>
    </PageWrapper>
  </Container>
);

export default ErrorFeature;
