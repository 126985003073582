import styled from 'styled-components';
import Flex from '@deprecated-ui/common/Flex';
import Button from '@deprecated-ui/common/Button';
import device from '@deprecated-ui/_style/device';

const PageWrapper = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
})`
  padding: 20px;
  flex-direction: column;

  > img {
    width: 40%;
    align-self: flex-end;
  }

  ${Button} {
    width: 100%;
    background: #46aa9e;
    margin-top: 20px;
    color: #ffffff;
    font-size: 16px;
  }

  @media ${device.laptop} {
    flex-direction: row;
    > img {
      margin-right: 50px;
      width: 200px;
    }
    ${Button} {
      width: auto;
    }
  }
`;

const Container = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
})`
  max-width: 1300px;
  height: 100vh;
  margin: auto;
`;

// eslint-disable-next-line import/prefer-default-export
export { PageWrapper, Container };
